import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TpnCalculatorService } from "../../services/tpn-calculator.service";
import { isTPNExcededErrorObj, tpnformValue } from "../../data";
import {
  TPNFinalCalculationInterface,
  TPNFormInterface,
  TPNFluidStep2Interface,
  TPNFluidStep3Interface,
  IsTPNExceedErrorInterface,
} from "../../models";
import { Subject, merge } from "rxjs";
import { filter, pairwise, takeUntil, tap } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import {
  getTotalTpnConsumed,
  getTpnFluidConsumedInfo,
  getpnValue,
} from "../../utils";
import { Patient } from "src/app/models/patient";
import {
  CALCULATE_CURRENT_WEIGHT,
  calculateCurrentWeight,
} from "src/app/support-functions/calculateWeight";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "cp-tpn-calculator",
  templateUrl: "./tpn-calculator.component.html",
  styleUrls: ["./tpn-calculator.component.scss"],
})
export class TpnCalculatorComponent implements OnDestroy {
  totalTPN: number;
  totalLipids: number;
  totalTpnAvaiable: number;
  isTPNExcededErrorObj: IsTPNExceedErrorInterface = { ...isTPNExcededErrorObj };
  finalCalculationInfo: TPNFinalCalculationInterface = {
    dextroseIsoVol: "0 ml",
    dextroseRate: "0 ml/hr",
    dextroseAAAdditivesRate: "0 ml/hr",
    lipidsVitaminsRate: "0 ml/hr",
  };
  invalidPage: null | number;
  isListenerInit: boolean = false;

  @Input() set currPatient(patient: Patient) {
    if (patient?.CPMRN) {
      this._initializeListener();
      this._setWeight(patient);
      this.setTpnValues();
      this.setFinalCalculation();
    }
  }

  constructor(
    private _tpnCalculatorService: TpnCalculatorService,
    private _utilService: UtilService
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private unsubscribe$ = new Subject();

  public tpnCalculatorForm =
    this._tpnCalculatorService.createTPNForm(tpnformValue);

  public tpnPage: number = 1;

  public steps: string[] = ["Step 1", "Step 2", "Step 3", "Step 4"];

  public tpnFluidConsumedInfo = getTpnFluidConsumedInfo(
    this.tpnCalculatorForm.value as TPNFormInterface
  );

  public get getTpnVolumeStep2Info(): TPNFluidStep2Interface {
    return this.tpnFluidConsumedInfo.step2;
  }

  public get getTpnVolumeStep3Info(): TPNFluidStep3Interface {
    return this.tpnFluidConsumedInfo.step3;
  }

  public get step1(): FormGroup {
    return this.tpnCalculatorForm.get("step1") as FormGroup;
  }

  public get step2(): FormGroup {
    return this.tpnCalculatorForm.get("step2") as FormGroup;
  }

  public get step3(): FormGroup {
    return this.tpnCalculatorForm.get("step3") as FormGroup;
  }

  public get step4(): FormGroup {
    return this.tpnCalculatorForm.get("step4") as FormGroup;
  }

  public prevPage() {
    this.tpnPage = this.tpnPage - 1;
  }

  public nextPage() {
    this.tpnPage = this.tpnPage + 1;
  }

  private _setWeight(patient: Patient) {
    const currentWeightPayload: CALCULATE_CURRENT_WEIGHT = {
      weightHistory: patient?.weightHistory,
      weightObj: patient?.weightObj,
      patientType: patient?.patientType,
      weightInGrams: false,
      weight: patient?.weight,
      addUnits: false,
    };
    const weight = calculateCurrentWeight(currentWeightPayload);
    const step1 = { ...tpnformValue.step1, weight };
    this.tpnCalculatorForm.setValue({
      ...tpnformValue,
      step1,
    });
  }

  public get isTPNConsumed(): boolean {
    return this.tpnCalculatorForm.hasError("exceedsLimit");
  }

  public changePage(pageNumber: number) {
    if (this.isGoToPage(pageNumber)) return;
    this.tpnPage = pageNumber;
    this._setFormValueToZero();
  }

  public isGoToPage(pageNumber: number) {
    return (
      (this.invalidPage && this.invalidPage < pageNumber) ||
      this.step1.invalid ||
      this.totalTPN < 0
    );
  }

  private _initializeListener(): void {
    if (this.isListenerInit) return;
    this.isListenerInit = true;
    this.tpnCalculatorForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this._updateInvalidPageState()),
        filter(() => this.tpnPage !== 4),
        pairwise()
      )
      .subscribe(
        ([prevFormValues, formValues]: [
          TPNFormInterface,
          TPNFormInterface
        ]) => {
          this.setTpnValues(formValues);
          this.setFinalCalculation();
          this.checkTPNExceededErrors(prevFormValues, formValues);
        }
      );

    merge(
      this.step4.get("dextroseInfusionPct").valueChanges,
      this.step4.get("dextroseIsoPct").valueChanges
    )
      .pipe(filter(() => this.tpnPage == 4))
      .subscribe(() => {
        this.setFinalCalculation();
      });
  }
  checkTPNExceededErrors(
    prevFormValues: TPNFormInterface,
    currentForm: TPNFormInterface
  ) {
    if (!this.isTPNConsumed) {
      this.isTPNExcededErrorObj = { ...isTPNExcededErrorObj };
      return;
    }
    const prevValues = { ...prevFormValues.step2, ...prevFormValues.step3 };
    const currentValues = { ...currentForm.step2, ...currentForm.step3 };
    Object.entries(prevValues).forEach(([key, value]) => {
      if (currentValues[key] != value) {
        this.isTPNExcededErrorObj[key] = true;
      }
    });
  }

  private _updateInvalidPageState(): void {
    if (this.tpnCalculatorForm.invalid && !this.invalidPage) {
      this.invalidPage = this.tpnPage;
    }
    if (!this.tpnCalculatorForm.invalid) {
      this.invalidPage = null;
    }
  }

  private _setFormValueToZero(): void {
    const controls = (
      this.tpnCalculatorForm.get("step" + this.tpnPage) as FormGroup
    ).controls;
    Object.entries(controls).forEach(([key, control]) => {
      if (["weight", "requiredTFI"].includes(key)) return;
      const controlValue = control.value;
      if (controlValue === null) {
        control.setValue(0, { emitEvent: false, onlySelf: true });
      }
    });
  }

  private setTpnValues(
    formValues: TPNFormInterface = this.tpnCalculatorForm
      .value as TPNFormInterface
  ): void {
    const step1Value = formValues.step1;
    this.totalTPN = this._utilService.getDecimalsNum(getpnValue(step1Value));
    this.tpnFluidConsumedInfo = getTpnFluidConsumedInfo(formValues);
    const consumedTPN = getTotalTpnConsumed(this.tpnCalculatorForm);
    this.totalTpnAvaiable = this._utilService.getDecimalsNum(
      this.totalTPN > consumedTPN ? this.totalTPN - consumedTPN : 0
    );
  }

  private setFinalCalculation() {
    this.finalCalculationInfo = this._tpnCalculatorService.setFinalCaclculation(
      this.tpnCalculatorForm,
      this.totalTpnAvaiable
    );
  }
}
