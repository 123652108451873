import { IsTPNExceedErrorInterface } from "../models";

export enum FeedTypeEnum {
  EBM_TERM = "EBM-Term",
  EBM_PRETERM = "EBM-Preterm",
  RCM_TERM = "RCM-Term",
  RCM_PRETERM = "RCM-Preterm",
}

export const tpnformValue = {
  step1: {
    weight: 0,
    requiredTFI: 0,
    feeds: 0,
    feedType: FeedTypeEnum.EBM_TERM,
    drugs: 0,
    infusions: 0,
    transfusions: 0,
    others: 0,
  },
  step2: {
    aminoAcid: 0,
    lipids: 0,
    hmf: 0,
  },
  step3: {
    sodium: 0,
    potassium: 0,
    calcium: 0,
    phosphate: 0,
    multivitamin: 0,
    minerals: 0,
  },
  step4: {
    totalFluidDextrose: 0,
    dextroseInfusionPct: 15,
    dextroseIsoPct: 5,
    dextrose25Pct: null,
    dextroseAAAdditives: null,
    lipidsVitamins: null,
    gir: {
      fluid: null,
      feeds: null,
      medicineInfusion: 0,
    },
    nutritions: {
      totalCalories: null,
      protienIntake: null,
      lipids: null,
      calcium: null,
      phosphorus: null,
      vitaminA: null,
      vitaminD: null,
      totalNPC: null,
      npcRatio: null,
    },
  },
};

export enum FeedingSupplementsEnum {
  HMF = "HMF",
  LBW_FORMULA = "LBW Formula",
  SIMYL_MCT_OIL = "Simyl MCT Oil",
}

export const feedTypes = [
  FeedTypeEnum.EBM_TERM,
  FeedTypeEnum.EBM_PRETERM,
  FeedTypeEnum.RCM_TERM,
  FeedTypeEnum.RCM_PRETERM,
];

export const dextroseIsoPctOptions = [0, 5, 10, 15];

export const isTPNExcededErrorObj: IsTPNExceedErrorInterface = {
  aminoAcid: false,
  lipids: false,
  sodium: false,
  potassium: false,
  calcium: false,
  phosphate: false,
  multivitamin: false,
  minerals: false,
};

export enum SugarEnum {
  EBM_TERM = 7.1,
  EBM_PRETERM = 6.1,
  RCM_TERM = 7.1,
  RCM_PRETERM = 8.5,
  HMF = 0.49,
}

export const feedTypeSugarMap = {
  [FeedTypeEnum.EBM_TERM]: SugarEnum.EBM_TERM,
  [FeedTypeEnum.EBM_PRETERM]: SugarEnum.EBM_PRETERM,
  [FeedTypeEnum.RCM_TERM]: SugarEnum.RCM_TERM,
  [FeedTypeEnum.RCM_PRETERM]: SugarEnum.RCM_PRETERM,
  [FeedingSupplementsEnum.HMF]: SugarEnum.HMF,
};

export enum FinalCalcFieldEnum {
  TOTAL_CALORIES = "Total Calories",
  PROTIEN = "Protein",
  LIPIDS = "Lipids",
  CALCIUM = "Calcium",
  PHOSPHORUS = "Phosphorus",
  VITAMIN_A = "Vitamin A",
  VITAMIN_D = "Vitamin D",
}

export const nutritionCalcMap = {
  [FeedTypeEnum.EBM_TERM]: {
    [FinalCalcFieldEnum.TOTAL_CALORIES]: 65,
    [FinalCalcFieldEnum.PROTIEN]: 1.8,
    [FinalCalcFieldEnum.LIPIDS]: 3.2,
    [FinalCalcFieldEnum.CALCIUM]: 26,
    [FinalCalcFieldEnum.PHOSPHORUS]: 12,
    [FinalCalcFieldEnum.VITAMIN_A]: 120,
    [FinalCalcFieldEnum.VITAMIN_D]: 5,
  },
  [FeedTypeEnum.EBM_PRETERM]: {
    [FinalCalcFieldEnum.TOTAL_CALORIES]: 77,
    [FinalCalcFieldEnum.PROTIEN]: 2.2,
    [FinalCalcFieldEnum.LIPIDS]: 4.4,
    [FinalCalcFieldEnum.CALCIUM]: 26,
    [FinalCalcFieldEnum.PHOSPHORUS]: 11,
    [FinalCalcFieldEnum.VITAMIN_A]: 170,
    [FinalCalcFieldEnum.VITAMIN_D]: 5,
  },
  [FeedTypeEnum.RCM_TERM]: {
    [FinalCalcFieldEnum.TOTAL_CALORIES]: 67,
    [FinalCalcFieldEnum.PROTIEN]: 1.4,
    [FinalCalcFieldEnum.LIPIDS]: 3.1,
    [FinalCalcFieldEnum.CALCIUM]: 41.2,
    [FinalCalcFieldEnum.PHOSPHORUS]: 20.6,
    [FinalCalcFieldEnum.VITAMIN_A]: 178.85,
    [FinalCalcFieldEnum.VITAMIN_D]: 29,
  },
  [FeedTypeEnum.RCM_PRETERM]: {
    [FinalCalcFieldEnum.TOTAL_CALORIES]: 80,
    [FinalCalcFieldEnum.PROTIEN]: 2.73,
    [FinalCalcFieldEnum.LIPIDS]: 3.9,
    [FinalCalcFieldEnum.CALCIUM]: 99,
    [FinalCalcFieldEnum.PHOSPHORUS]: 49.5,
    [FinalCalcFieldEnum.VITAMIN_A]: 973,
    [FinalCalcFieldEnum.VITAMIN_D]: 97,
  },
  [FeedingSupplementsEnum.HMF]: {
    [FinalCalcFieldEnum.TOTAL_CALORIES]: 3.37,
    [FinalCalcFieldEnum.PROTIEN]: 0.27,
    [FinalCalcFieldEnum.LIPIDS]: 0.04,
    [FinalCalcFieldEnum.CALCIUM]: 15.8,
    [FinalCalcFieldEnum.PHOSPHORUS]: 7.9,
    [FinalCalcFieldEnum.VITAMIN_A]: 200,
    [FinalCalcFieldEnum.VITAMIN_D]: 133,
  },
};
