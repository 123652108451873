import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  inject,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-tpn-input",
  templateUrl: "./tpn-input.component.html",
  styleUrls: ["./tpn-input.component.scss"],
  host: {
    class: "flex-1 cp-input-field-container",
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TpnInputComponent,
      multi: true,
    },
  ],
})
export class TpnInputComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input() options: string[] = [];
  @Input() label: string = "";
  @ContentChild("info") contentInfo!: TemplateRef<any>;

  private unsubscribe$: Subject<any> = new Subject<any>();

  private _fb: FormBuilder = inject(FormBuilder);

  private _cdr = inject(ChangeDetectorRef);

  onChange: (value: any) => void;
  onTouched: () => void;

  public tpnInputForm = this._fb.group({
    value: this._fb.control(""),
    unit: this._fb.control(""),
  });
  isDisabled: boolean;

  get value() {
    return this.tpnInputForm.get("value");
  }

  get unit() {
    return this.tpnInputForm.get("unit");
  }

  constructor() {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  writeValue(obj: any): void {
    this.value.setValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.value.disable();
      this.unit.disable();
      this._cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.initializeListeners();
  }

  onFocusIn() {
    this.onTouched();
  }

  initializeListeners() {
    this.tpnInputForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.onChange && this.onChange(this.value.value);
      });
  }
}
