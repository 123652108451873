<div [formGroup]="form">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Total fluid for dextrose (ml/day):"
        [control]="totalFluidDextrose"
      >
        <cp-tpn-input formControlName="totalFluidDextrose"> </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="%Dextrose to be infused*:"
        [control]="dextroseInfusionPct"
      >
        <cp-tpn-input formControlName="dextroseInfusionPct"> </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="%D/Iso-P*:" [control]="dextroseIsoPct">
        <cp-tpn-input
          formControlName="dextroseIsoPct"
          [options]="dextroseIsoPctOptions"
        >
        </cp-tpn-input>
        <div class="cp-mt-1">
          {{ finalCalculationInfo.dextroseIsoVol }}
        </div>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="25%D (ml/day):" [control]="dextrose25Pct">
        <cp-tpn-input formControlName="dextrose25Pct">
          <span inputInfo class="cp-ml-1">
            {{ finalCalculationInfo.dextroseRate }}</span
          >
        </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Dextrose + AA + Additives:"
        [control]="dextroseAAAdditives"
      >
        <cp-tpn-input formControlName="dextroseAAAdditives"> </cp-tpn-input>
        <div class="cp-mt-1">
          {{ finalCalculationInfo.dextroseAAAdditivesRate }}
        </div>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Lipids + Multivitamins:"
        [control]="lipidsVitamins"
      >
        <cp-tpn-input formControlName="lipidsVitamins"> </cp-tpn-input>
        <div class="cp-mt-1">
          {{ finalCalculationInfo.lipidsVitaminsRate }}
        </div>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-mt-2 cp-mb-1">
    <span class="cp-big-font-2 cp-text-normal">GIR (mg/kg/min)</span>
  </div>
  <div class="cp-mt-1">
    <mat-divider></mat-divider>
  </div>
  <div formGroupName="gir">
    <div class="cp-grid-row cp-grid-row--no-margin">
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Fluid:" [control]="fluid">
          <cp-tpn-input formControlName="fluid"> </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Feeds:" [control]="feeds">
          <cp-tpn-input formControlName="feeds"> </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field
          label="Medicine + infusion:"
          [control]="medicineInfusion"
        >
          <cp-tpn-input formControlName="medicineInfusion"> </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
    </div>
  </div>

  <div class="cp-mt-2 cp-mb-1">
    <span class="cp-big-font-2 cp-text-normal">Nutritions</span>
  </div>
  <div class="cp-mt-1">
    <mat-divider></mat-divider>
  </div>
  <div formGroupName="nutritions">
    <div class="cp-grid-row cp-grid-row--no-margin">
      <div class="col-1-of-3">
        <cp-tpn-form-field
          label="Total calories (kcal/day):"
          [control]="totalCalories"
        >
          <cp-tpn-input formControlName="totalCalories">
            <ng-template #info>
              <cp-range-info>80-120 kcal/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field
          label="Protein intake (g/kg/day):"
          [control]="protienIntake"
        >
          <cp-tpn-input formControlName="protienIntake">
            <ng-template #info>
              <cp-range-info>1.5-3.5 g/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Lipids (g/kg/day):" [control]="lipids">
          <cp-tpn-input formControlName="lipids">
            <ng-template #info>
              <cp-range-info>1-3 g/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
    </div>
    <div class="cp-grid-row cp-grid-row--no-margin">
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Calcium (mg/kg/day):" [control]="calcium">
          <cp-tpn-input formControlName="calcium">
            <ng-template #info>
              <cp-range-info>60-80 mg/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field
          label="Phosphorus (mg/kg/day):"
          [control]="phosphorus"
        >
          <cp-tpn-input formControlName="phosphorus">
            <ng-template #info>
              <cp-range-info>40-60 mg/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Vitamin A (IU/kg/day):" [control]="vitaminA">
          <cp-tpn-input formControlName="vitaminA">
            <ng-template #info>
              <cp-range-info>700-1500 IU/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
    </div>
    <div class="cp-grid-row cp-grid-row--no-margin">
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Vitamin D (IU/kg/day):" [control]="vitaminD">
          <cp-tpn-input formControlName="vitaminD">
            <ng-template #info>
              <cp-range-info>400-1000 IU/kg/day</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field label="Total NPC (kcal/day):" [control]="totalNPC">
          <cp-tpn-input formControlName="totalNPC"> </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
      <div class="col-1-of-3">
        <cp-tpn-form-field label="NPC/N ratio (kcal/g):" [control]="npcRatio">
          <cp-tpn-input formControlName="npcRatio">
            <ng-template #info>
              <cp-range-info>80-150 kcal/g</cp-range-info>
            </ng-template>
          </cp-tpn-input>
        </cp-tpn-form-field>
      </div>
    </div>
  </div>
</div>
