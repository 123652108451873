<div [formGroup]="form">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field
        label="Amino acids (10%) (g/kg/day):"
        [control]="aminoAcid"
      >
        <cp-tpn-input
          formControlName="aminoAcid"
          [class.cp-form-invalid]="isTPNExcededErrorObj.aminoAcid"
        >
          <ng-template #info>
            <cp-range-info>1.5-4g/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="aminoAcidVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.aminoAcid"
            [control]="aminoAcid"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
    <div class="col-1-of-2">
      <cp-tpn-form-field label="Lipids (20%) (g/kg/day):" [control]="lipids">
        <cp-tpn-input
          formControlName="lipids"
          [class.cp-form-invalid]="isTPNExcededErrorObj.lipids"
        >
          <ng-template #info>
            <cp-range-info>1-3g/kg/day</cp-range-info>
          </ng-template>
        </cp-tpn-input>
        <div class="display-flex flex-wrap cp-mt-1">
          <cp-tpn-message [message]="lipidsVolume"></cp-tpn-message>
          <cp-tpnform-error-text
            *ngIf="isTPNExcededErrorObj.lipids"
            [control]="lipids"
          ></cp-tpnform-error-text>
        </div>
      </cp-tpn-form-field>
    </div>
  </div>
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2">
      <cp-tpn-form-field label="HMF (g/day):" [control]="hmf">
        <cp-tpn-input label="HMF (g/day):" formControlName="hmf">
          <ng-template #info>
            <cp-range-info>1 Sachet = 1 gm</cp-range-info>
          </ng-template>
        </cp-tpn-input>
      </cp-tpn-form-field>
    </div>
  </div>
</div>
