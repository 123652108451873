<div class="display-flex flex-column cp-h-100">
  <label class="cp-field-label">{{ label }}</label>
  <ng-content></ng-content>
  <ng-container *ngIf="control && errorKey" [ngSwitch]="errorKey">
    <span class="error-text" *ngSwitchCase="'required'"
      >This field is required</span
    >
    <span class="error-text" *ngSwitchCase="'min'"
      >Minimum {{ control.errors[errorKey].min }}</span
    >
    <span class="error-text" *ngSwitchCase="'max'"
      >Maximum {{ control.errors[errorKey].max }}</span
    >
  </ng-container>
</div>
