<div
  class="display-flex flex-gap-1 flex-1 tpn-input"
  [formGroup]="tpnInputForm"
  (focusin)="onFocusIn()"
>
  <div class="flex-1 tpn-input__select" *ngIf="options?.length; else inputText">
    <cp-dropdown
      formControlName="value"
      placeholder="Select"
      [data]="options"
      [multiple]="false"
      [searchable]="false"
      inputClass="order-select"
    ></cp-dropdown>
  </div>
  <ng-template #inputText>
    <div class="flex-1 tpn-input__text display-flex align-items-center">
      <input
        formControlName="value"
        matInput
        type="number"
        class="cp-input-field"
        [ngClass]="{
          'tpn-input__info-input': !!contentInfo
        }"
      />
      <mat-icon
        *ngIf="contentInfo"
        class="cp-icon"
        position="bottom-center"
        [cpPopover]="contentInfo"
        >info</mat-icon
      >
      <div class="tpn-input__input-info">
        <ng-content select="[inputInfo]"></ng-content>
      </div>
    </div>
  </ng-template>
</div>
